export default {
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      infoData: null
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    moreInfo: {
      type: Object
    }
  },
  methods: {
    dialogEvent () {
      this.$emit('input')
    },
    whatsappMessageURL (cellphone) {
      return `https://api.whatsapp.com/send?phone=${cellphone}&text=Usted%20realizo%20una%20reserva,%20por%20favor%20nos%20confirma%20su%20asistencia?`
    }
  }
}
