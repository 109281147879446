export default {
  data () {
    return {
      dialogCompleted: false,
      provisionalIdBooking: null,
      provitionalAction: null,
      singleSelect: false,
      selected: [],
      headersNoShow: [
        // { text: this.$t('bookings.image'), sortable: false, value: 'contact.image' },
        { text: this.$t('bookings.name'), sortable: false, value: 'contact.name' },
        { text: this.$t('bookings.code'), sortable: false, value: 'code' },
        { text: this.$t('bookings.promoCode'), sortable: false, value: 'meta.promoCode' },
        { text: this.$t('bookings.date'), sortable: false, value: 'date' },
        { text: this.$t('bookings.hour'), sortable: false, value: 'time' },
        { text: this.$t('bookings.amountPeople'), sortable: false, value: 'numberPeople' },
        { text: this.$t('bookings.discount'), sortable: false, value: 'discount' },
        { text: this.$t('general.options'), sortable: false, value: 'options' }
      ],
      bookingsNoShow: [],
      itemsPerPageNoShow: 15,
      pageNoShow: 1,
      optionsNoShow: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 3) {
        this.initializeBookingNoShow()
      }
    },
    pageNoShow () {
      this.initializeBookingNoShow()
    },
    itemsPerPageActive () {
      this.initializeBookingNoShow()
    }
  },
  methods: {
    async initializeBookingNoShow () {
      const { bookings, options } = await this.initializeBooking(this.pageActive, 'no_show', this.itemsPerPageActive)
      this.bookingsNoShow = bookings
      this.optionsNoShow = options
    }
  }
}
