import { ServiceFactory } from '@/services/ServiceFactory'
const BookingService = ServiceFactory.get('BookingService')

export default {
  data () {
    return {
      dialogCompleted: false,
      provisionalIdBooking: null,
      provitionalAction: null,
      singleSelect: false,
      selected: [],
      headersActive: [
        // { text: this.$t('bookings.image'), sortable: false, value: 'contact.image' },
        { text: this.$t('bookings.name'), sortable: false, value: 'contact.name' },
        { text: this.$t('bookings.code'), sortable: false, value: 'code' },
        { text: this.$t('bookings.promoCode'), sortable: false, value: 'meta.promoCode' },
        { text: this.$t('bookings.date'), sortable: false, value: 'date' },
        { text: this.$t('bookings.hour'), sortable: false, value: 'time' },
        { text: this.$t('bookings.amountPeople'), sortable: false, value: 'numberPeople' },
        { text: this.$t('bookings.discount'), sortable: false, value: 'discount' },
        { text: this.$t('general.options'), sortable: false, value: 'options' }
        // { text: this.$t('bookings.moreInfo'), sortable: false, value: 'cancel' }
      ],
      bookingsActive: [],
      itemsPerPageActive: 15,
      pageActive: 1,
      optionsActive: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 0) {
        this.initializeBookingActive()
      }
    },
    pageActive () {
      this.initializeBookingActive()
    },
    itemsPerPageActive () {
      this.initializeBookingActive()
    }
  },
  methods: {
    async initializeBookingActive () {
      const { bookings, options } = await this.initializeBooking(this.pageActive, 'active', this.itemsPerPageActive)
      this.bookingsActive = bookings
      this.optionsActive = options
    },
    provitionalBookingState (idBooking, action = 'complete') {
      this.dialogCompleted = true
      this.provisionalIdBooking = idBooking
      this.provitionalAction = action
    },
    async updateBookingState () {
      try {
        const bookingData = await BookingService.put(this.provisionalIdBooking, this.provitionalAction)
        this.dialogCompleted = false
        await this.initializeBookingActive()
        this.$notify({
          title: 'Exito!!',
          type: 'success',
          text: bookingData.msg
        })
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
