export default {
  data () {
    return {
      singleSelect: false,
      selected: [],
      headersCancelled: [
        // { text: this.$t('bookings.image'), align: 'start', sortable: false, value: 'contact.image' },
        { text: this.$t('bookings.name'), sortable: false, value: 'contact.name' },
        { text: this.$t('bookings.code'), sortable: false, value: 'code' },
        { text: this.$t('bookings.promoCode'), sortable: false, value: 'meta.promoCode' },
        { text: this.$t('bookings.date'), sortable: false, value: 'date' },
        { text: this.$t('bookings.hour'), sortable: false, value: 'time' },
        { text: this.$t('bookings.amountPeople'), sortable: false, value: 'numberPeople' },
        { text: this.$t('bookings.discount'), sortable: false, value: 'discount' }
      ],
      bookingsCancelled: [],
      itemsPerPageCancelled: 15,
      pageCancelled: 1,
      optionsCancelled: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 2) {
        this.initializeBookingCancelled()
      }
    },
    pageCancelled () {
      this.initializeBookingCancelled()
    },
    itemsPerPageCancelled () {
      this.initializeBookingCancelled()
    }
  },
  methods: {
    async initializeBookingCancelled () {
      const { bookings, options } = await this.initializeBooking(this.pageCancelled, 'cancelled', this.itemsPerPageCancelled)
      this.loading = false
      this.bookingsCancelled = bookings
      this.optionsCancelled = options
    }
  }
}
