export default {
  data () {
    return {
      headersReview: [
        // { text: this.$t('bookings.image'), align: 'start', sortable: false, value: 'contact.image' },
        { text: this.$t('bookings.comments'), align: 'start', sortable: false, value: 'text' },
        { text: this.$t('bookings.date'), sortable: false, value: 'createdAt' },
        { text: this.$t('bookings.rating'), sortable: false, value: 'avg' }
      ],
      reviews: [],
      avgScore: 5,
      numberReviews: {},
      ratings: [5, 4, 3, 2, 1]
    }
  },
  watch: {
    tab () {
      if (this.tab === 4) {
        this.initializeBookingReview()
      }
    },
    pageCompleted () {
      this.initializeBookingReview()
    },
    itemsPerPageCompleted () {
      this.initializeBookingReview()
    }
  },
  methods: {
    async initializeBookingReview () {
      const { reviews, numberReviews, avgScore } = await this.initializeReviews()
      this.reviews = reviews
      this.avgScore = avgScore
      this.numberReviews = numberReviews
    }
  }
}
