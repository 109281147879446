import { ServiceFactory } from '@/services/ServiceFactory'
const BookingService = ServiceFactory.get('BookingService')

export default {
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      infoData: null,
      radioGroup: null,
      reasons: ['Llego tarde', 'No asistió', 'No estuvo de acuerdo con los términos', 'Otro'],
      reasonComment: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    bookingInfo: {
      type: Object
    }
  },
  methods: {
    dialogEvent () {
      this.$emit('input')
    },
    async cancelBooking () {
      try {
        if (this.radioGroup === 'Otro' && this.reasonComment.trim() === '') {
          this.$notify({
            title: 'Escriba la razón de cancelación',
            type: 'warning'
          })
          return
        }
        const payload = {
          cancelReason: this.radioGroup === 'Otro' ? this.reasonComment : this.radioGroup
        }
        const bookingData = await BookingService.cancelBooking(this.bookingInfo.zbBookingId, payload)
        this.dialogEvent()
        this.$notify({
          title: 'Reserva cancelada',
          type: 'success',
          text: bookingData.msg
        })
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    }
  }
}
