export default {
  data () {
    return {
      singleSelect: false,
      selected: [],
      headersCompleted: [
        // { text: this.$t('bookings.image'), align: 'start', sortable: false, value: 'contact.image' },
        { text: this.$t('bookings.name'), align: 'start', sortable: false, value: 'contact.name' },
        { text: this.$t('bookings.code'), sortable: false, value: 'code' },
        { text: this.$t('bookings.promoCode'), sortable: false, value: 'meta.promoCode' },
        { text: this.$t('bookings.date'), sortable: false, value: 'date' },
        { text: this.$t('bookings.hour'), sortable: false, value: 'time' },
        { text: this.$t('bookings.amountPeople'), sortable: false, value: 'numberPeople' },
        { text: this.$t('bookings.discount'), sortable: false, value: 'discount' }
      ],
      bookingsCompleted: [],
      itemsPerPageCompleted: 15,
      pageCompleted: 1,
      optionsCompleted: {
        success: false,
        totalItems: 0,
        totalPages: 0,
        currentPage: 0
      }
    }
  },
  watch: {
    tab () {
      if (this.tab === 1) {
        this.initializeBookingCompleted()
      }
    },
    pageCompleted () {
      this.initializeBookingCompleted()
    },
    itemsPerPageCompleted () {
      this.initializeBookingCompleted()
    }
  },
  methods: {
    async initializeBookingCompleted () {
      const { bookings, options } = await this.initializeBooking(this.pageCompleted, 'completed', this.itemsPerPageCompleted)
      this.loading = false
      this.bookingsCompleted = bookings
      this.optionsCompleted = options
    }
  }
}
