import { ServiceFactory } from '@/services/ServiceFactory'
// import { max } from 'moment';
import moment from 'moment'
// const BookingService = ServiceFactory.get('BookingService')
const SettingsService = ServiceFactory.get('SettingsService')

export default {
  data () {
    return {
      info: null,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      infoData: null,
      radioGroup: null,
      hours: [
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
        '00:00'
      ],
      postData: {
        date: [],
        // fromTime: '',
        // toTime: '',
        reason: ''
      },
      reasons: ['Llego tarde', 'No asistió', 'No estuvo de acuerdo con los términos', 'Otro'],
      reasonComment: '',
      closingData: {},
      minDate: '',
      maxDate: '',
      editable: false,
      loading: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    bookingInfo: {
      type: Object
    },
    openModal: {
      type: Boolean
    }
  },
  // created () {
  //   this.initialize()
  // },
  watch: {
    openModal: function () {
      if (this.openModal) {
        this.initialize()
      }
    }
  },
  mounted () {
    this.minMax()
  },
  methods: {
    minMax () {
      this.minDate = moment().format('YYYY-MM-DD')
      this.maxDate = ''
    },
    selectDate () {
      // if (this.postData.date) {
      //   var closing = moment(this.postData.date[0]).format('YYYY-MM-DD')
      //   var current = moment().format('YYYY-MM-DD')
      //   var isafter = moment(closing).isAfter(current)
      //   if (isafter) {
      //     this.editable = false
      //   } else {
      //     this.editable = true
      //   }
      // }
      this.maxDate = moment(this.postData.date[0]).add(6, 'days').format('YYYY-MM-DD')
    },
    dateString (value) {
      let val = moment(value).format('D [de] MMM YYYY')
      return val
    },
    // clearMaxDate () {
    //   this.maxDate = null
    // },
    async initialize () {
      try {
        this.minMax()
        const closingResponse = await SettingsService.getClosingDays()
        this.closingData = closingResponse.closingDays
        if (this.closingData) {
          var start = this.closingData.startDate.split(' ')
          var end = this.closingData.endDate.split(' ')
          this.postData.date = [start[0], end[0]]
          // this.postData.fromTime = start[1]
          // this.postData.toTime = end[1]
          this.postData.reason = this.closingData.reason[0]
          // var closing = moment(end[0]).format('YYYY-MM-DD')
          // var current = moment().format('YYYY-MM-DD')
          // var isafter = moment(closing).isAfter(current)
          // if (isafter) {
          //   this.editable = false
          // } else {
          //   this.editable = true
          // }
          this.editable = true
        } else {
          this.postData = {
            date: [],
            // fromTime: '',
            // toTime: '',
            reason: ''
          }
          this.editable = false
        }
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    dialogEvent () {
      this.postData = {}
      this.$emit('input')
    },
    async closeBusiness () {
      try {
        this.loading = true
        this.postData.date = this.postData.date.sort()
        const closingData = await SettingsService.saveClosingDays(this.postData)
        this.dialogEvent()
        this.loading = false
        this.$notify({
          title: 'Negocio cerrado',
          type: 'success',
          text: closingData.msg
        })
      } catch (error) {
        this.loading = false
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    },
    async cancelClosing () {
      try {
        const closingData = await SettingsService.deleteClosingDays()
        this.initialize()
        this.$notify({
          title: 'Cierre cancelado',
          type: 'success',
          text: closingData.msg
        })
      } catch (error) {
        this.$notify({
          title: 'Error!!',
          type: 'error',
          text: error.msg
        })
      }
    }
    // async cancelBooking () {
    //   try {
    //     if (this.radioGroup === 'Otro' && this.reasonComment.trim() === '') {
    //       this.$notify({
    //         title: 'Escriba la razón de cancelación',
    //         type: 'warning'
    //       })
    //       return
    //     }
    //     const payload = {
    //       cancelReason: this.radioGroup === 'Otro' ? this.reasonComment : this.radioGroup
    //     }
    //     const bookingData = await BookingService.cancelBooking(this.bookingInfo.zbBookingId, payload)
    //     this.dialogEvent()
    //     this.$notify({
    //       title: 'Reserva cancelada',
    //       type: 'success',
    //       text: bookingData.msg
    //     })
    //   } catch (error) {
    //     this.$notify({
    //       title: 'Error!!',
    //       type: 'error',
    //       text: error.msg
    //     })
    //   }
    // }
  }
}
