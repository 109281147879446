import ZbMoreinfoModal from './zb-moreinfo-modal'
import ZbCancelModal from './zb-cancel-modal'
import ZbCloseDateModal from './close-date-modal'
import ZbMixinBookingsActive from './ZbMixinBookingsActive'
import ZbMixinBookingsCompleted from './ZbMixinBookingsCompleted'
import ZbMixinBookingsCancelled from './ZbMixinBookingsCancelled'
import ZbMixinBookingsReviews from './ZbMixinBookingsReviews'
import ZbMixinBookingsNoShow from './ZbMixinBookingsNoShow'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { ServiceFactory } from '@/services/ServiceFactory'
const BookingService = ServiceFactory.get('BookingService')
const SettingsService = ServiceFactory.get('SettingsService')
moment.locale('es')
export default {
  name: 'ZbBookingsComponent',
  components: {
    ZbMoreinfoModal,
    ZbCancelModal,
    ZbCloseDateModal
  },
  mixins: [
    ZbMixinBookingsActive,
    ZbMixinBookingsCompleted,
    ZbMixinBookingsCancelled,
    ZbMixinBookingsReviews,
    ZbMixinBookingsNoShow
  ],
  data () {
    return {
      loadingButton: false,
      tab: null,
      moreinfoModal: false,
      cancelBookingModal: false,
      moreInfo: null,
      loading: true,
      self: this,
      publicPath: `${process.env.BASE_URL}images/user-default.png`,
      code: null,
      bookingInfo: null,
      closeDateModal: null,
      debt: {},
      dialog: false
    }
  },
  computed: {
    ...mapGetters({
      authCredentials$: 'authModule/authCredentials'
    })
  },
  created () {
    this.getBusinessDebt()
  },
  methods: {
    async initializeBooking (page, type, itemsPerPage) {
      this.loading = true
      try {
        const { bookings, currentPage, success, totalItems, totalPages } = await BookingService.get(page, type, itemsPerPage)
        this.loading = false
        return {
          bookings,
          options: {
            success,
            totalItems,
            totalPages,
            currentPage
          }
        }
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        return {}
      }
    },
    async initializeReviews () {
      this.loading = true
      try {
        const { reviews, numberReviews, avgScore, success, msg } = await BookingService.getReviews()
        this.loading = false
        if (success) {
          return {
            reviews,
            numberReviews,
            avgScore
          }
        } else {
          this.$notify({
            title: 'Advertencia',
            type: 'warning',
            text: msg
          })
          return {}
        }
      } catch (error) {
        this.$notify({
          title: 'Error:',
          type: 'error',
          text: error.msg
        })
        return {}
      }
    },
    moreInfoFunction (item) {
      this.moreInfo = item
      this.moreinfoModal = true
    },
    openCloseDateModal () {
      this.closeDateModal = true
    },
    cancelBooking (item) {
      this.bookingInfo = item
      this.cancelBookingModal = true
    },
    peopleString (value) {
      if (value > 1) {
        return `${value} ${this.$t('bookings.people')}`
      }
      return `${value} ${this.$t('bookings.person')}`
    },
    dateString (value) {
      let today = new Date()
      let nextDay = new Date(today)
      nextDay.setDate(today.getDate() + 1)

      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()

      const ddNext = String(nextDay.getDate()).padStart(2, '0')
      const mmNext = String(nextDay.getMonth() + 1).padStart(2, '0')
      const yyyyNext = nextDay.getFullYear()

      today = yyyy + '-' + mm + '-' + dd
      nextDay = yyyyNext + '-' + mmNext + '-' + ddNext

      if (value === today) {
        return 'Hoy'
      } else if (value === nextDay) {
        return 'Mañana'
      } else {
        return value
      }
    },
    checkCode (item) {
      this.code = item.code
      this.verifyCode()
    },
    verifyCode () {
      this.loadingButton = true
      BookingService.saveCode(this.code)
        .then((resp) => {
          this.$notify({
            type: 'success',
            text: resp.msg
          })
          if (resp.success) {
            this.$swal({
              title: 'Reserva verificada',
              html: `
                <p>${resp.booking.contact.name}</p>
                <p>${moment(resp.booking.datetime).format('LLLL')}</p>
                <p>Descuento del -${resp.booking.discount * 100}%</p>
                ${resp.promoCode ? `<p class="tw-font-bold">Código promocional ${resp.promoCode}</p>` : ''}
              `,
              icon: 'success'
            })
          }
          this.loadingButton = false
          this.code = ''
          this.initializeBookingActive()
        }).catch((err) => {
          this.$notify({
            title: 'Error:',
            type: 'error',
            text: err.msg
          })
          this.loadingButton = false
        })
    },
    closeScheduleModal () {
      this.initializeBookingActive()
    },
    getPayement (value) {
      return value?.order?.payment?.payed || false
    },
    async getBusinessDebt () {
      try {
        const debtResponse = await SettingsService.getDebt()
        this.debt = debtResponse
        if (this.debt.debt.length > 0) {
          this.dialog = true
        } else {
          this.dialog = false
        }
      } catch (error) {
        this.dialog = false
      }
    }
  }
}
